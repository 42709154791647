import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';


const Header: React.FC = () => {
    return (
        <Navbar expand="lg" variant="light" bg="light">
            <Container>
                <Navbar.Brand href="#">My Landing Page</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse id="navbarColor03">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Nav.Link href="#">Home</Nav.Link></Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#">Features</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
