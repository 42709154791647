// src/components/MainContent.tsx

import React from 'react';
import '../styles/MainContent.scss';

const MainContent: React.FC = () => {
  return (
    <div className="main-content">
      <section className="hero">
        <h1>Welcome to Our Landing Page</h1>
        <p>Your solution for <em>insert your value proposition here</em>.</p>
      </section>
      <section className="features">
        <h2>Our Features</h2>
        <div className="feature-list">
          <div className="feature">
            <h3>Feature One</h3>
            <p>Detail about this feature.</p>
          </div>
          <div className="feature">
            <h3>Feature Two</h3>
            <p>Detail about this feature.</p>
          </div>
          <div className="feature">
            <h3>Feature Three</h3>
            <p>Detail about this feature.</p>
          </div>
          {/* Add more features as needed */}
        </div>
      </section>
      {/* Add more sections as needed */}
    </div>
  );
};

export default MainContent;
